export default [
  {
    title: 'Home',
    icon: 'HomeIcon',
    route: 'dashboard',
    resource: 'dashboard'
  },
  {
    title: 'Report',
    icon: 'PieChartIcon',
    route: 'dashboard-report',
    resource: 'dashboard-report'
  }
]
