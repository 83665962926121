export default [
  {
    header: 'Finances',
    resource: 'Auth',
  },
  {
    title: 'Finance',
    icon: 'CreditCardIcon',
    resource: 'transaction',
    route: 'apps-transaction-list',
    action: 'read',
    // children: [
    //   {
    //     title: 'List',
    //     icon: 'MenuIcon',
    //     resource: 'transaction',
    //   },
    //   // {
    //   //   title: 'Preview',
    //   //   route: { name: 'apps-transaction-preview', params: { id: 4987 } },
    //   // },
    //   // {
    //   //   title: 'Edit',
    //   //   route: { name: 'apps-transaction-edit', params: { id: 4987 } },
    //   // },
    //   {
    //     title: 'Add',
    //     route: { name: 'apps-transaction-add' },
    //     action: 'create',
    //     resource: 'transaction',
    //   },
    // ],
  },
  // {
  //   title: 'Add transactions',
  //   icon: '',
  //   route: 'apps-transactions-add',
  //   // acl: {
  //   action: 'add',
  //   resource: 'transactions',
  //   // },
  // },
  {
    title: 'Purchase',
    icon: 'FilePlusIcon',
    route: 'apps-purchase-list',
    resource: 'purchase',
    action: 'read',
    // children: [
    //   {
    //     route: 'apps-purchase-list',
    //     icon: 'MenuIcon',
    //     title: 'List',
    //     action: 'read',
    //     resource: 'purchase',
    //   },
    //   // {
    //   //   title: 'Preview',
    //   //   route: { name: 'apps-purchase-preview', params: { id: 4987 } },
    //   // },
    //   // {
    //   //   title: 'Edit',
    //   //   route: { name: 'apps-purchase-edit', params: { id: 4987 } },
    //   // },
    //   // {
    //   //   title: 'Add',
    //   //   route: { name: 'apps-purchase-add' },
    //   //   action: 'create',
    //   //   resource: 'purchase',
    //   // },
    //   // {
    //   //   title: 'Add Shipment',
    //   //   route: { name: 'apps-purchase-add-shipment' },
    //   //   action: 'create',
    //   //   resource: 'purchase',
    //   // },
    // ],
  },
  {
    title: 'Sale',
    icon: 'FileMinusIcon',
    resource: 'sale',
    route: 'apps-sales-list',
    action: 'read',
    // children: [
    //   {
    //     title: 'List',
    //     icon: 'MenuIcon',
    //     resource: 'sale',
    //   },
    //   // {
    //   //   title: 'Preview',
    //   //   route: { name: 'apps-sales-preview', params: { id: 4987 } },
    //   // },
    //   // {
    //   //   title: 'Edit',
    //   //   route: { name: 'apps-sales-edit', params: { id: 4987 } },
    //   // },
    //   {
    //     title: 'Add',
    //     route: { name: 'apps-sales-add' },
    //     action: 'create',
    //     resource: 'sale',
    //   },
    //   // {
    //   //   title: 'Income form',
    //   //   route: { name: 'apps-sales-payment' },
    //   //   action: 'add',
    //   //   resource: 'transactions',
    //   // },
    // ],
  },
  {
    title: 'Warehouse',
    icon: 'DatabaseIcon',
    route: 'apps-warehouse-management',
    action: 'read',
    resource: 'warehouse',
  },
  {
    title: 'Clients',
    icon: 'UserIcon',
    route: 'apps-clients-list',
    action: 'read',
    resource: 'client',
  },
  // {
  //   title: 'Warehouse',
  //   icon: 'FileTextIcon',
  //   resource: 'warehouse',
  //   children: [
  //     {
  //       title: 'List',
  //       route: 'apps-warehouse-list',
  //       action: 'read',
  //       resource: 'warehouse',
  //     },
  //     // {
  //     //   title: 'Preview',
  //     //   route: { name: 'apps-warehouse-preview', params: { id: 4987 } },
  //     // },
  //     // {
  //     //   title: 'Edit',
  //     //   route: { name: 'apps-warehouse-edit', params: { id: 4987 } },
  //     // },
  //     {
  //       title: 'Add',
  //       route: { name: 'apps-warehouse-add' },
  //       action: 'create',
  //       resource: 'warehouse',
  //     },
  //   ],
  // },
  // {
  //   title: 'Product',
  //   icon: '',
  //   resource: 'product',
  //   children: [
  //     ,
  //     // {
  //     //   title: 'Preview',
  //     //   route: { name: 'apps-warehouse-preview', params: { id: 4987 } },
  //     // },
  //     // {
  //     //   title: 'Edit',
  //     //   route: { name: 'apps-warehouse-edit', params: { id: 4987 } },
  //     // },
  //     // {
  //     //   title: 'Add',
  //     //   route: { name: 'apps-product-add' },
  //     //   action: 'create',
  //     //   resource: 'product',
  //     // },
  //   ],
  // },
  // {
  //   title: 'Activity',
  //   icon: 'ActivityIcon',
  //   route: 'activities',
  //   resource: 'activity',
  // },
  // {
  //   header: 'Devices',
  // },
  // {
  //   title: 'Batteries',
  //   icon: 'BatteryIcon',
  //   route: 'batteries',
  //   resource: 'batteries',
  // },
  // {
  //   title: 'Stations',
  //   icon: 'BoxIcon',
  //   route: 'stations',
  //   resource: 'stations',
  // },
  {
    title: 'Settings',
    icon: 'BoxIcon',
    children: [
      {
        title: 'Users',
        icon: 'UserCheckIcon',
        route: 'apps-users-list',
        action: 'read',
        resource: 'user',
      },
      {
        title: 'Role',
        icon: 'ShieldIcon',
        route: 'apps-roles-list',
        action: 'read',
        resource: 'role',
      },
      {
        title: 'Warehouse',
        icon: 'DatabaseIcon',
        route: 'apps-warehouse-list',
        action: 'read',
        resource: 'warehouse',
      },
      {
        title: 'Car',
        icon: 'Navigation2Icon',
        route: 'apps-car-list',
        action: 'read',
        resource: 'car',
      },
      {
        title: 'Product',
        icon: 'LayersIcon',
        route: 'apps-product-list',
        action: 'read',
        resource: 'product',
      },
      {
        title: 'Kassas',
        icon: 'ArchiveIcon',
        route: 'apps-kassa-list',
        // acl: {
        action: 'read',
        resource: 'kassa',
        // },
      },
    ],
  },
  // {
  //   header: 'Settings',
  // },
]
