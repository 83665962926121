<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21" />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="align-items-center flex-grow-1 d-flex">
      <dark-Toggler class="d-block" />
      <!-- primary -->
      <b-dropdown v-ripple.400="'rgba(113, 102, 240, 0.15)'" :text="$store.state.app.currentProject ? $store.state.app.currentProject.name : 'No Project'" variant="flat-primary">
        <b-dropdown-item v-if="$store.state.app.projects.length === 0" disabled>
          No Project
        </b-dropdown-item>
        <b-dropdown-item v-for="pr in $store.state.app.projects" :key="pr.id" :disabled="pr.id === $store.state.app.currentProject.id" @click="switchPr(pr)">
          {{ pr.name }}
        </b-dropdown-item>
        <b-dropdown-divider />
        <!-- <b-dropdown-item>Create New</b-dropdown-item> -->
      </b-dropdown>
      <!-- datepicker -->
      <!-- <div v-if="$store.state.app.currentPage == 'dashboard'" class="d-flex align-items-center">
        <feather-icon icon="CalendarIcon" size="16" />
        <flat-pickr v-model="rangePicker" :config="{ mode: 'range' }" class="form-control flat-picker bg-transparent border-0 shadow-none" placeholder="YYYY-MM-DD" />
      </div> -->
      <!-- datepicker -->
    </div>
    <b-navbar-nav class="nav align-items-center ml-auto">
      <locale />
      <user-dropdown />
    </b-navbar-nav>
  </div>
</template>

<script>
import axios from '@axios'

import { BLink, BNavbarNav, BDropdown, BDropdownItem, BDropdownDivider } from 'bootstrap-vue'
// import flatPickr from 'vue-flatpickr-component'

import { getHomeRouteForLoggedInUser } from '@/auth/utils'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import Ripple from 'vue-ripple-directive'
import DarkToggler from './components/DarkToggler.vue'
import Locale from './components/Locale.vue'
import UserDropdown from './components/UserDropdown.vue'

export default {
  components: {
    BLink,
    // Navbar Components
    Locale,
    BNavbarNav,
    DarkToggler,
    UserDropdown,
    BDropdown,
    BDropdownItem,
    BDropdownDivider
    // flatPickr
  },
  directives: {
    Ripple
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {}
    }
  },
  // data() {
  //   return {
  //     rangePicker: ['2019-05-01', '2019-05-10']
  //   }
  // },
  methods: {
    async switchPr(pr) {
      await this.$store.commit('app/UPDATE_CURRENT_PROJECT', pr)
      const userData = JSON.parse(localStorage.getItem('userData'))

      await axios
        .post('/switchProject', {
          userId: userData.id,
          projectId: pr.id
        })
        .then(response => {
          const ability = response.data.group.Group.permissions
          this.$ability.update(ability)
          userData.role = response.data.group.Group.name
          localStorage.setItem('userData', JSON.stringify(userData))

          // ? This is just for demo purpose as well.
          // ? Because we are showing eCommerce app's cart items count in navbar
          // this.$store.commit('app-ecommerce/UPDATE_CART_ITEMS_COUNT', this.userData.extras.eCommerceCartItemsCount)

          // ? This is just for demo purpose. Don't think CASL is role based in this case, we used role in if condition just for ease
          const goTo = getHomeRouteForLoggedInUser(userData.role)
          console.log(this.$router.currentRoute)
          console.log(goTo)
          this.$router
            .go()
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: `Приветсвуем ${userData.fullName || userData.username}`,
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                  text: `Вы успешно переключили проект и вошли как ${userData.role}.`
                }
              })
            })
            .catch(err => {
              console.log(err)
              // An error occurred
            })
        })
        .catch(error => {
          console.log(error)
        })
    }
  }
}
</script>
